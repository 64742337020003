<template>
  <div class="form-group">
    <label
      v-if="label"
      class="form-label"
    >
      {{ label }}
    </label>
    <vue-simple-suggest
      v-validate.initial="validate"
      destyled
      filter-by-query
      :value="currentValue"
      :name="name"
      :disabled="disabled"
      :debounce="debounce"
      :placeholder="placeholder"
      :controls="navigate"
      :styles="autoCompleteStyle"
      :list="list"
      :max-suggestions="list.length"
      :value-attribute="valueAttribute"
      :display-attribute="displayAttribute"
      @hover="hoverItem"
      @input="inputHandler"
    >
      <div
        :ref="getRef(suggestion)"
        slot="suggestion-item"
        slot-scope="{ suggestion }"
        :class="{ isHovered: suggestion === hovered }"
      >
        <span class="suggestion-text">{{ suggestion[displayAttribute] || suggestion }}</span>
      </div>
    </vue-simple-suggest>
    <span
      v-if="error || controlledError"
      class="invalid-feedback d-block"
    >
      {{ error || controlledError }}
    </span>
  </div>
</template>

<script>
import VueSimpleSuggest from 'vue-simple-suggest';

export default {
  name: 'CpAutoComplete',
  components: {
    VueSimpleSuggest,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    visibleItems: {
      type: [String, Number],
      default: 5,
    },
    value: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    valueAttribute: {
      type: String,
      default: 'id',
    },
    displayAttribute: {
      type: String,
      default: 'title',
    },
    error: {
      type: String,
      default: '',
    },
    validate: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      hovered: null,
      debounce: 500,
      navigate: {
        selectionUp: [38],
        selectionDown: [40],
        select: [13],
      },

      controlledValue: this.value,
      controlledError: '',
    };
  },
  computed: {
    getRef() {
      return name => `suggest-${name}`;
    },
    currentValue() {
      return this.value !== null ? this.value : this.controlledValue;
    },
    autoCompleteStyle() {
      return {
        vueSimpleSuggest: 'position-relative',
        defaultInput: `form-control ${(this.error || this.controlledError) ? 'border border-danger' : ''}`,
        suggestions: `cp-suggestions show-count-${this.visibleItems}`,
        suggestItem: 'cp-suggest-item',
      };
    },
  },
  methods: {
    isCpInput() {
      return true;
    },
    inputHandler(val) {
      this.$emit('input', val);
    },
    hoverItem(hovered) {
      this.hovered = hovered;
      this.$refs[`suggest-${hovered}`].scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style lang="scss">
  $height: 25px;
  @for $i from 1 through 100 {
    .show-count-#{$i} {
      max-height: $height * $i
    }
  }

  .cp-suggest-item {
    height: $height;
    line-height: $height;
    list-style-type: none;
    .suggestion-text{
      color: white;
      font-weight: bold;
      padding: 0 0.875rem;
    }
  }

  .cp-suggestions {
    position: absolute;
    width: 100%;
    overflow: auto;
    color: white;
    z-index: 1000;
    padding: 0 !important;
    background-color: rgba(41, 41, 41, 0.8);
    box-shadow: inset 0 0 10px 5px rgba(0,0,0,0.2);
    background-clip: padding-box;
  }

  .isHovered {
    background-color: #4191ff !important;
  }
</style>
